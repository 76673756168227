.wizardButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 30px;
}

@media (max-width: 1100px) {
    .wizardButtonContainer {
        flex-direction: column-reverse;
    }
}

.combinationPlaylistCreator {
    margin-top: 20px;
    margin-bottom: 20px;
    background: #1f2326;
    border-radius: 5px;
    padding: 5px;
}

.combinationPlaylistCreatorHeader {
    padding: 20px 5px 15px 17px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.combinationPlaylistCreatorHeaderText {
    font-size: 15pt;
    font-weight: bold;
    margin-top: -2px;
}

.wizardAddButton {
    border: 2px solid #546081;
    border-radius: 25px;
    padding: 7px 20px;
    font-size: 10pt;
    cursor: pointer;
    text-align: center;
    margin: 10px;
    width: 64px;
}

@media (hover: hover) {
    .wizardAddButton:hover {
        background-color: #546081;
    }
}

.wizardAddButton.active {
    background-color: #4E5874;
    border: 2px solid #4E5874;
}

.wizardRemoveButtonContainer {
    display: flex;
    align-items: center
}

.wizardRemoveButtonPretext {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 12pt;
}

.wizardRemoveButton {
    background-color: #546081;
    border: 2px solid #546081;
    min-width: 50px;
    border-radius: 25px;
    padding: 7px 20px;
    font-size: 10pt;
    cursor: pointer;
    text-align: center;
    margin: 10px
}

@media (hover: hover) {
    .wizardRemoveButton:hover {
        background: #4E5874;
        border: 2px solid #4E5874;
    }
}

.wizardRemoveButton:active {
    background: #4E5874;
    border: 2px solid #4E5874;
}

.valuePickerButton {
    min-width: 50px;
    border-radius: 25px;
    padding: 7px 20px;
    font-size: 10pt;
    cursor: pointer;
    text-align: center;
    color: white;
    margin: 10px;
    background: #394044;
    border: solid #394044;
}

@media (hover: hover) {
    .valuePickerButton:hover {
        background: #34393d;
        border: solid #34393d;
    }
}

.criterionCreatorContainer {
    display: flex;
    flex-direction: column;
}

.criterionCreatorHeader {
    padding: 8px 0 3px 17px;
    font-size: 13pt;
    font-weight: bold;
}

.criterionCreator {
    margin-bottom: 10px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #1f2326;
}

.criterionButtons {
    display: flex;
    flex-wrap: wrap;
}

.criterionRemoveButtons {
    display: flex;
    flex-wrap: wrap;
}

.criterionAddButtons {
    display: flex;
}

@media (max-width: 768px) {
    .criterionRemoveButtons {
        flex-direction: column;
        flex-grow: 1;
    }
    
    .criterionAddButtons {
        flex-wrap: wrap;
    }
    
    .criterionButtons {
        flex-direction: column;
    }
}

.playlistNamer {
    margin-left: 5px;
    margin-top: 30px;
}

.playlistNamer > .userInputContainer {
    margin-top: 10px;
}

.deleteCombinationPlaylistButton {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}

@media (max-width: 600px) {
    .combinationPlaylistCreatorHeader {
        padding: 20px 15px 15px 17px;
        flex-direction: column-reverse;
        align-items: flex-start
    }
}
