.errorMessage {
    color: #ff7272;
}

.smallMarginTop {
    margin-top: 5px;
}

.marginTop {
    margin-top: 20px;
}

.marginBottom {
    margin-bottom: 30px;
}
