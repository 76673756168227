@font-face {
    font-display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/poppins-v20-latin-600.woff2') format('woff2');
}

.unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a {
    font-weight: bold;
}

.premiumText {
    color: #ffd700;
}

.bold {
    font-weight: bold;
}

#pageContainer {
    padding: 15px 15px 30px 15px;
    flex: 1;
    background: #181c1e;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 49px;
}

#pageContent {
    width: 1170px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    border-radius: 7px;
}

.pageContentSegment {
    background-color: #2c3032;
    padding: 15px;
    margin-top: 13px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.pageContentSegment > h2 {
    margin-top: 10px;
}

p {
    margin: 10px;
}

@media (max-width: 1250px) {
    #pageContent {
        width: 1050px;
    }
}

@media (max-width: 992px) {
    #pageContent {
        width: 940px;
    }
}

@media (max-width: 950px) {
    #pageContent {
        width: 850px;
    }
}

@media (max-width: 860px) {
    #pageContent {
        width: 750px;
    }
}

@media (max-width: 768px) {
    #pageContent {
        width: 550px;
    }
}

@media (max-width: 600px) {
    #pageContent {
        width: 450px;
    }
}


@media (max-width: 470px) {
    #pageContent {
        width: 380px;
    }
}


@media (max-width: 414px) {
    #pageContent {
        width: 360px;
    }
}

@media (max-width: 380px) {
    #pageContent {
        width: 310px;
    }
}

@media (max-width: 350px) {
    #pageContent {
        width: 270px;
    }
}

@media (max-width: 320px) {
    #pageContent {
        width: 250px;
    }
}

@media (max-width: 295px) {
    #pageContent {
        width: 240px;
    }
}

.userInput {
    height: 25px;
    width: 50%;
    font-size: 12pt;
    padding: 10px 10px;
    border: 2px solid #575a64;
    border-radius: 4px;
    outline: 0;
    color: #fff;
    background-color: #1f2326;
}

.userInput::placeholder {
    color: #9b9b9b;
}

@media (max-width: 768px) {
    .userInput {
        width: 100%;
    }
}

@media (max-width: 380px) {
    .userInput {
        width: 100%;
    }
}

@media (max-width: 350px) {
    .userInput {
        width: 100%;
    }
}

@media (max-width: 300px) {
    .userInput {
        width: 100%;
    }
}

.userInputContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 20px 40px 20px 10px;
}

.userInputContainer > input:focus {
    border: 2px solid #5c698c;
}

.userInputContainer.removeTopMargin {
    margin-top: 0;
}

input[type=submit] {
    white-space: normal;
}

.chevron {
    align-self: center;
    display: flex;
    flex-grow: 1;
    flex-direction: row-reverse
}

.chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-225deg);
    vertical-align: top;
    width: 0.45em;
    margin-right: 10px;
    
}

.chevron.right:before {
    left: 0;
    margin-top: 3px;
    transform: rotate(45deg);
}

.centerChild {
    display: flex;
    justify-content: center
}

input[type="checkbox"] {
    margin-right: 10px;
    width: 17px;
    height: 17px;
}

h1 {
    font-size: 20pt;
}

h2 {
    font-size: 16pt;
}

h3 {
    font-size: 12pt;
}

tr.bordered {
    border-bottom: 1px solid #575a64;
}