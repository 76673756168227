.preferenceButton {
    min-width: 50px;
    border-radius: 25px;
    padding: 7px 20px;
    font-size: 10pt;
    cursor: pointer;
    text-align: center;
    color: white;
    margin: 10px
}

.preferenceButton.selected {
    background: #546081;
    border: solid #546081;
}


.preferenceButton.unavailable, .preferenceButton.deselected {
    background: #394044;
    border: solid #394044;
}

@media (hover: hover) {
    .preferenceButton.deselected:hover, .preferenceButton.unavailable:hover {
        background: #34393d;
        border: solid #34393d;
    }

    .preferenceButton.selected:hover {
        background: #4E5874;
        border: solid #4E5874;
    }
}

.preferenceContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.preferenceButtonError {
    position: absolute;
    background-color: #1f2326;
    border: 1px solid #575a64;
    color: #f1f1f1;
    border-radius: 3px;
    padding: 8px;
    animation: fadeOut 4500ms forwards;
    animation-delay: 1s;
    width: 200px;
    pointer-events: none;
    opacity: 0.92;
    z-index: 99997;
}

.preferenceButtonIcon {
    margin-right: 10px;
}

@keyframes fadeOut {
    from {
        opacity: 0.92;
    }
    to {
        opacity: 0;
    }
}

.numberOfApplicableSongs {
    font-weight: bold;
}