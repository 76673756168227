.notAvailable {
    opacity: 0.50;
}

.feature, .subscriptionDescriptor {
    font-weight: bold;
    font-size: 11pt;
}

#membershipLevelContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

@media (max-width: 920px) {
    #membershipLevelContainer {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.membershipLevel {
    width: 400px;
    background: #1f2326;
    padding: 15px;
    border-radius: 7px;
    border: 1px solid #575a64;
    color: #f1f1f1;
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.premiumMembershipLevel {
    border-color: #ffd700;
}

@media (max-width: 470px) {
    .membershipLevel {
        width: 90%;
    }
}

.membershipLevelTeaser > ul {
    list-style-type: none;
    padding-left: 10px;
}

.teaserAvailability {
    display: flex;
    margin-top: 5px;
}

.notAvailableFeature {
    color: #a3a3a3;
}

.availabilityIcon {
    margin-right: 10px;
}

.membershipHeader {
    margin-top: 0;
}

.premiumHeader {
    color: #ffd700;
}

.price {
    font-size: 30pt;
    margin-bottom: 0;
}

.price.premium {
    color: #ffd700;
}

.priceExplainer {
    margin-top: 0;
    font-size: 11pt;
}

.disclaimer {
    font-size: 10pt;
}

.upgradeMembershipLevelButtonContainer {
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
}

.plansConfirmButton {
    border: solid #546081;
    border-radius: 10px;
    padding: 7px 45px;
    font-size: 13pt;
    cursor: pointer;
    text-align: center;
    background: #546081;
    color: whitesmoke;
    font-weight: 600;
    width: 275px;
}

.plansConfirmButtonLink {
    align-self: center;
    justify-content: center;
    display: flex;
    width: 300px;
}

@media (max-width: 470px) {
    .plansConfirmButtonLink {
        width: 90%;
    }
}

.plansConfirmButton:active, .plansConfirmButton:hover {
    background: #4E5874;
    border: solid #4E5874;
}

.plansConfirmButton.premium {
    background-color: #ffd700;
    border-color: #ffd700;
    color: #323232;
    font-weight: 650;
    padding: 8px 45px;
}

.plansConfirmButton.premium:hover, .plansConfirmButton.premium:active {
    background: #d5b500;
    border: solid #d5b500;
}

#featureTableSection {
    margin-top: 30px;
}

.upgradeMembershipForm {
    display: flex;
    justify-content: center;
}

.tempItemPrice {
    color: #1f2326
}

#subscriptionButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media (max-width: 470px) {
    #subscriptionButtons {
        flex-direction: column;
        align-items: center;
    }
    
    #subscriptionButtons > .smallestButton:nth-child(odd) {
        margin-bottom: 20px;
    }
}