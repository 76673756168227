.subgenres {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
}

.subgenreList {
    width: 100%;
}

.preferenceButtonsSectionHeader.subgenresManaged {
    margin-top: 20px;
}

.preferenceButtonsSection.subgenresAvailable {
    margin-bottom: -5px;
}