.buttonConfirmation {
    border: solid #546081;
    border-radius: 10px;
    padding: 7px 45px;
    font-size: 15pt;
    cursor: pointer;
    text-align: center;
    background: #546081;
    color: whitesmoke;
}

.buttonConfirmation:active, .buttonConfirmation:hover {
    background: #4E5874;
    border: solid #4E5874;
}

.buttonCancel {
    border: solid #7a7a7a;
    border-radius: 10px;
    padding: 7px 45px;
    font-size: 15pt;
    cursor: pointer;
    text-align: center;
    background: #7a7a7a;
    color: whitesmoke;
}

.buttonCancel:active, .buttonCancel:hover {
    background: #6c6c6c;
    border: solid #6c6c6c;
}

.buttonWarning {
    border: solid #8f5656;
    border-radius: 10px;
    padding: 7px 45px;
    font-size: 15pt;
    cursor: pointer;
    text-align: center;
    background: #8f5656;
    color: whitesmoke;
}

.buttonWarning:active, .buttonWarning:hover {
    background: #7e4b4b;
    border: solid #7e4b4b;
}

.buttonMarginTop {
    margin-top: 30px;
}

.buttonMarginBottom {
    margin-bottom: 30px;
}

.button {
    width: 425px;
    font-weight: 600;
    align-self: center;
}

@media (max-width: 1250px) {
    .button {
        width: 350px;
    }
}

@media (max-width: 440px) {
    .button {
        width: 300px;
    }
}

@media (max-width: 350px) {
    .button {
        width: 260px;
    }
}

@media (max-width: 320px) {
    .button {
        width: 230px;
    }
}

.button.noFixedWidth {
    min-width: unset;
}


.smallerButton {
    font-weight: 600;
    width: 300px;
    align-self: center;
}

@media (max-width: 350px) {
    .smallerButton {
        width: 260px;
    }
}

@media (max-width: 350px) {
    .smallerButton {
        width: 260px;
    }
}

@media (max-width: 320px) {
    .smallerButton {
        width: 230px;
    }
}

.loginButton {
    display: flex;
    flex-direction: column;
}

.centerButton {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.loginSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.loginSection.noMarginTop {
    margin-top: 0;
}

.privacyAndTermsDisclaimer {
    font-size: 9pt;
    font-weight: 400;
    margin-bottom: 30px;
}

.privacyAndTermsDisclaimerTerms {
    margin-bottom: 10px;
}

.smallestButton {
    min-width: 175px;
}

.smallestButton > .buttonWarning, .smallestButton > .buttonConfirmation {
    font-size: 12pt;
    padding: 5px 20px;
    font-weight: 500;
}

.smallestButton.noFixedWidth {
    min-width: unset;
}

.outlineBoxContainer {
    display: flex;
}

.outlineButton {
    padding: 4px 8px 6px;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
    border: 2px solid #546081;
}

@media (hover: hover) {
    .outlineButton:hover {
        background-color: #546081;
    }
}

.outlineButton:active {
    background-color: #4E5874;
    border: 2px solid #4E5874;
}

.outlineButtonIcon {
    margin-right: 5px;
    margin-top: 2px;
}

@media (max-width: 380px) {
    .outlineButton {
        font-size: 9pt;
    }
}

.outlineButton.warning {
    border: 2px solid #8f5656;
}

@media (hover: hover) {
    .outlineButton.warning:hover {
        background-color: #8f5656;
    }
}

.outlineButton.warning:active {
    background: #7e4b4b;
    border: 2px solid #7e4b4b;
}