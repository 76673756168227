.landingPageIntro {
    padding-bottom: 60px;
}

.introDesktop {
    display: block;
}

.introDesktopTextAndAnimation {
    display: flex;
    margin-bottom: 40px;
}

.introText {
    font-size: 13pt;
}

.introMobile {
    display: none;
}

.loginButtonMobile {
    margin-bottom: 30px;
}

@media (max-width: 950px) {
    .introDesktop {
        display: none;
    }

    .introMobile {
        display: block;
    }
}

.playlistsContainer {
    margin-top: 30px;
    margin-bottom: 30px;
}

.playlists {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    margin-bottom: 15px;
}

.playlist {
    padding: 20px;
    background-color: #1f2326;
    width: 200px;
    border-radius: 5px;
    border: solid 2px #575a64;
    cursor: pointer;
}

@media (hover: hover) {
    .playlist:hover {
        animation: fadeInBackgroundColor 0.1s forwards;
    }
}

@keyframes fadeInBackgroundColor {
    0% {
        background-color: #1f2326;
    }
    100% {
        background-color: #292f33;
    }
}

.playlistHeaderContainer {
    display: flex;
    justify-content: center;
}

.playlistHeader {
    margin-top: 0;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    font-size: 13pt;
    font-weight: bold;
}

.playlistPremiumIcon {
    margin-left: 5px;
    margin-top: 1px;
}

.playlistIcon {
    display: flex;
    justify-content: center;
}

.playlistExamples {
    margin-top: 20px;
    font-size: 11pt;
    font-style: italic;
}

.combinationPlaylistsHeaderContainer {
    display: flex;
    align-items: center;
}

.combinationPlaylistsHeaderStar {
    margin-top: 3px;
    margin-left: 7px;
}

.combinationPlaylistContainer {
    display: flex;
    margin-bottom: 30px;
}

.combinationGraphic {
    font-size: 18pt;
    font-style: italic;
    margin-right: 15px;
}

.combinationGraphic.desktop {
    width: 739px;
    height: 327px;
}

.combinationGraphic.mobile {
    width: 332px;
    height: 663px;
    display: none;
}

.combinationGraphicMobileContainer {
    display: flex;
    justify-content: space-around;
}

.combinationGraphic {
    border: solid 2px #575a64;
    border-radius: 5px;
    background: #1f2326;
}

@media (max-width: 1250px) {
    .playlists {
        padding: 5px;
    }

    .playlist {
        padding: 15px;
        width: 180px;
    }

    .playlistExamples {
        font-size: 10pt;
    }
}

@media (max-width: 950px) {
    .combinationPlaylistContainer {
        flex-direction: column-reverse;
    }

    .combinationPlaylistsHeader {
        justify-content: flex-start;
        margin-right: 0;
    }

    .combinationGraphic {
        display: flex;
        text-align: right;
        justify-content: space-around;
        margin-top: 30px;
        margin-right: 0;
        width: 90%;
    }

    .combinationGraphic.desktop {
        display: none;
    }

    .combinationGraphic.mobile {
        display: flex;
    }

    .playlists {
        flex-direction: column;
        align-items: center;
    }

    .playlist {
        margin-bottom: 20px;
    }

    .playlists {
        margin-bottom: 0;
    }

    .playlistHeader {
        font-size: 11pt;
    }

    .playlistPremiumIcon {
        margin-top: 0;
    }
}

.playlistGroupLink {
    font-weight: unset;
}