#header {
    border-bottom: 1px solid #282828;
    display: flex;
    flex-direction: row;
    min-height: 65px;
    position: fixed;
    width: 100%;
    z-index: 99998;
    color: #d7d7d7;
    background: #2f3133;
}

.loadingHeader {
}

.headerTitleMobileContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 50px;
    align-items: center;
    cursor: pointer;
}

.headerTitleDesktopContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
}

.headerTitle {
    font-family: 'Cairo Play', sans-serif;
}

#headerDesktop {
    display: flex;
    width: 100%;
}

#headerMobile {
    display: none;
}

@media (max-width: 980px) {
    #headerDesktop {
        display: none;
    }

    #headerMobile {
        display: flex;
        align-items: center;
        margin-left: 10px;
        width: 100%;
    }
}

@keyframes fadeIn {
    0% {
        transform: translateX(-2rem);
    }
    100% {
        transform: translateX(0);
    }
}

#headerMobileSideMenu {
    height: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background: #2f3133;
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.2s ease-in-out;
}

.closeBurgerMenuContainer {
    display: flex;
    flex-direction: column;
    background: #29292c;
}

.burgerMenu {
    cursor: pointer;
}

.closeBurgerMenu {
    align-self: end;
    padding: 15px;
    cursor: pointer;
}

.headerLink {
    font-weight: 645;
    padding: 10px;
    width: 180px;
    text-align: center;
    cursor: pointer;
    font-size: 13pt;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid #2f3133;
}

.headerLink:hover {
    background: #25292d;
    border-bottom: 3px solid #25292d;
}


.headerLink.selected {
    border-bottom: 3px solid #5c698c;
}

.headerLinkContent {
    display: flex;
    justify-content: space-around;
    font-weight: 645;
}

.headerLinkMobile {
    font-weight: 645;
    padding: 10px 10px 10px 15px;
    width: 215px;
    text-align: center;
    color: #c7c7c7;
    cursor: pointer;
    font-size: 13pt;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 5px;
    margin-left: 5px;
}

.headerLinkMobile.selected {
    border-bottom: 3px solid #546081;
}


@media (hover: hover) {
    .headerLinkMobile:hover {
        background: #25292d;
    }
}

.headerLinkMobile:active {
    background: #25292d;
}

.headerLinkContentMobile {
    display: flex;
    justify-content: space-around;
}

.headerIconMobile {
    margin-right: 20px;
}

.headerMobileBottomSection {
    flex-grow: 1;
    align-items: flex-end;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column-reverse;
}

.headerButton {
    font-weight: 600;
}

.headerLinkContainer {
    display: flex;
}

.headerIcon {
    margin-right: 13px;
}

.headerEndSection {
    display: flex;
    flex-grow: 1;
    flex-direction: row-reverse;
    align-items: center;
    margin-right: 20px;
    margin-left: 60px;
}

.headerEndSectionButton {
    display: flex;
    padding: 10px;
    cursor: pointer;
}

.headerEndSectionButtonBackground {
    border-radius: 50%;
    margin-left: 10px;
    padding: 5px;
}

.headerEndSectionButtonBackground:hover {
    background: #25292d;
}

.headerMobileLogin {
    flex-grow: 1;
    align-items: flex-end;
    margin-bottom: 15px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
}