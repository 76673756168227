.preferenceManagementModuleContainer {
    margin-bottom: 20px;
}

.preferenceManagementModule {
    background: #1f2326;
    padding: 5px;
}

.preferenceManagementModule.collapsed {
    border-radius: 5px;
}

.preferenceManagementModule:active {
    background: #25292d;
}

@media (hover: hover) {
    .preferenceManagementModule:hover {
        background: #25292d;
    }
}

.preferenceManagementModule.expanded {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.preferenceManagementModuleHeader {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
}

.preferenceManagementModuleHeaderNumber {
    margin-right: 15px;
    font-size: 40pt;
    font-weight: bold;
    display: none;
}

.preferenceManagementModuleHeaderLineBreak {
    flex-grow: 1;
    height: 2px;
    background-color: #9c9fad;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 2px;
    border: none;
}

.preferenceManagementModuleHeaderChevronContainer {
    display: flex;
    margin-right: 5px;
    flex-direction: row;
    flex-grow: 1;
}

.preferenceManagementModuleHeaderExplainer {
    display: flex;
    flex-direction: row;
    margin-left: 2px;
    margin-top: 5px;
}

.preferenceManagementModuleHeaderExplainerContainer {
    display: flex;
    flex-direction: column;
}

.preferenceManagementModuleHeaderExplainerText {
    font-size: 15pt;
    font-weight: bold;
    margin-top: -2px;
}

.preferenceManagementModuleHeaderStarContainer {
    display: flex;
}

.premiumPreferencesHeaderStarDesktop {
    margin-left: 10px;
    align-self: center;
}

.premiumPreferencesHeaderStarMobile {
    display: none;
    margin-top: 5px;
}

@media (max-width: 600px) {
    .premiumPreferencesHeaderStarDesktop {
        display: none;
    }
    
    .premiumPreferencesHeaderStarMobile {
        display: block;
    }
}

.preferenceManagementModuleHeaderExamplesStarContainer {
    display: flex;
}

.preferenceManagementModuleHeaderExplainerExamples {
    font-size: 11pt;
    font-style: italic;
    color: #a1a1a1;
}

.preferenceManagementSubModule {
    margin-bottom: 10px;
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #1f2326;
}

.preferenceIcon {
    margin-right: 15px;
    display: flex;
    align-items: center;
}