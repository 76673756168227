.premiumHeader {
    color: #ffd700;
}

.playlistColumn {
    font-weight: bold;
}

.group > h2 {
    margin-top: 25px;
}

.getAccessWithPremiumButtonContainer {
    display: flex;
    justify-content: center;
}

.accessWithPremiumButtonLink {
    align-self: center;
    justify-content: center;
    display: flex;
}

.accessWithPremiumButton {
    background-color: #ffd700;
    border: solid #ffd700;
    color: #323232;
    font-weight: bold;
    padding: 8px 0px;
    width: 425px;
    border-radius: 10px;    
    font-size: 15pt;
    cursor: pointer;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.accessWithPremiumButton:hover, .accessWithPremiumButton:active {
    background: #d5b500;
    border: solid #d5b500;
}


@media (max-width: 1250px) {
    .accessWithPremiumButton {
        width: 350px;
    }
}

@media (max-width: 440px) {
    .accessWithPremiumButton {
        width: 300px;
    }
}

@media (max-width: 350px) {
    .accessWithPremiumButton {
        width: 260px;
    }
}

@media (max-width: 320px) {
    .accessWithPremiumButton {
        width: 230px;
    }
}

.combinationShowcase {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}