@media (min-width: 1251px) {
    .preferenceContainer {
        margin-bottom: 150px;
    }
}

.playlistTabContainer {
    display: flex;
}

@media (hover: hover) {
    .playlistTab:hover {
        background-color: #394044;
    }
}

.playlistTab:active {
    background-color: #394044;
}

.playlistTab {
    font-size: 15pt;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 7px;
}


@media (max-width: 460px) {
    .playlistTab {
        font-size: 14pt;
    }
}

@media (max-width: 400px) {
    .playlistTab {
        font-size: 13pt;
    }
}


@media (max-width: 350px) {
    .playlistTab {
        font-size: 10pt;
        margin-right: 3px;
    }
}

.activeTab {
    background: #394044;
}

.tabRemainder {
    width: 100%;
}

.distributionInProgress {
}