.preferenceButtonsSectionHeader {
    font-weight: bold;
    margin-left: 10px;
}

.preferenceButtonsSection {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 5px;
}