.playlistAnimation {
    flex-shrink: 0;
    flex-direction: row;
    display: flex;
    padding-left: 10px;
    justify-content: center;
}

.songsToPlaylistGap {
    width: 175px;
}

.playlistAnimationLikedSongs {
    background-color: #1f2326;
    border-radius: 5px;
}

.playlistAnimationLikedSongsTitle {
    padding: 10px;
}

.playlistAnimationLikedSongsList {
    padding-top: 10px;
    padding-bottom: 10px;
}

.likedSongArrowBuffer {
    padding: 2px;
}

.likedSongContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.likedSong {
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
    transition: transform 0.5s ease-in-out;
}

.likedSongTitle {
    font-weight: 500;
    font-size: 11pt;
}

.likedSongArtist {
    font-size: 13pt;
}

.likedMarking {
    margin-right: 15px;
}

.likedMarking.current {
    animation: animateColor 2500ms forwards;
}

@keyframes animateColor {
    from {
        color: #ffffff;
    }
    10%, 90% {
        color: #2ac22a;
    }
    to {
        color: #ffffff;
    }
}

.managedPlaylists {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.managedPlaylistArrowBuffer {
    background-color: #1f2326;
    border-radius: 5px;
    padding: 5px;
}

.managedPlaylist {
    width: 100px;
    padding: 13px;
    display: flex;
    justify-content: center;
}

@media (max-width: 440px) {
    .managedPlaylist {
        width: 75px;
        font-size: 9pt;
        padding: 10px;
    }

    .likedSongTitle {
        font-size: 9pt;
    }

    .likedSongArtist {
        font-size: 10pt;
    }

    .songsToPlaylistGap {
        width: 130px;
    }
}

@media (max-width: 380px) {
    .managedPlaylist {
        width: 60px;
    }
}

.managedPlaylists div:not(:first-child) {
    margin-top: 10px;
}

.managedPlaylist.targeted:not(.breakBetweenRuns), .managedPlaylistArrowBuffer.targeted:not(.breakBetweenRuns) {
    animation: animateBackgroundColor 2500ms forwards infinite;
}

@keyframes animateBackgroundColor {
    from {
        background-color: #1f2326;
    }
    10%, 90% {
        background-color: #25292d;
    }
    to {
        background-color: #1f2326;
    }
}

.managedPlaylists, .playlistAnimationLikedSongs {
    z-index: 1;
}

.animatedArrow {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 2500ms forwards, fadeOutSlow 2500ms forwards;
}

@keyframes dash {
    0%, 10% {
        stroke-dashoffset: 1000;
    }
    90%, 100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeOutSlow {
    0%, 80% {
        opacity: 1;
    }
    100% {
        opacity: 00;
    }
}