.currentPreferences {
    margin-top: 80px;
}

@media (max-width: 340px) {
    .definitionColumn {
        display: none;
    }
}

.deleteColumn {
    vertical-align: center;
}


td {
    padding: 10px;
    text-align: left;
}

th {
    padding: 10px;
    text-align: left;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 30px;
}

.deleteCombinationButton {
    border: solid #8f5656;
    border-radius: 10px;
    font-size: 12pt;
    cursor: pointer;
    text-align: center;
    background: #8f5656;
    color: whitesmoke;
    padding: 5px 10px;
}

.deleteCombinationButton:hover {
    background: #7e4b4b;
    border: solid #7e4b4b;
}