.deletePreferenceButton {
    border: solid #8f5656;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    background: #8f5656;
    color: whitesmoke;
    min-width: 50px;
    padding: 7px 20px;
    font-size: 10pt;
    margin: 10px
}

.deletePreferenceButton:hover {
    background: #7e4b4b;
    border: solid #7e4b4b;
}

.deletePreferenceContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}