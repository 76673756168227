.preferenceManagementConfirmButtons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
}

.buttonSplitter {
    margin-bottom: 20px;
    margin-top: 20px;
}

@media (max-width: 850px) {
    .preferenceManagementConfirmButtons {
        flex-direction: column-reverse;
    }
}