#footer {
    min-height: 50px;
    margin-top: auto;
    background: #2f3133;
    color: #c4c4c4;
    padding: 15px;
    z-index: 1;
}

.footerSection > a {
    color: #d5d5d5;
}

.footerSection {
    padding-bottom: 5px;
}