.toggle {
    display: flex;
    align-items: center;
    width: 50px;
    height: 26px;
    cursor: pointer;
    background-color: #9b9b9b;
    border-radius: 25px;
}

.toggle > input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0 0 0 4px;
}

.slider {
    background-color: white;
    transition: .2s;
    cursor: pointer;
    height: 18px;
    width: 18px;
    border-radius: 50%;
}

.toggle.toggled {
    background-color: #546081;
}

input:checked + .slider {
    transform: translateX(24px);
}

.toggle.marginRight {
    margin-right: 10px;
}