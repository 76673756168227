.premiumPopup {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 425px;
    background: #1f2326;
    padding: 15px;
    border-radius: 7px;
    border: 1px solid #ffd700;
    color: #f1f1f1;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .premiumPopup {
        width: 300px;
    }
}

.teaserAvailability {
    display: flex;
    margin-top: 5px;
}

.upgradeNowButton {
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 25px;
    font-size: 13pt;
    cursor: pointer;
    text-align: center;
    width: 275px;
    background-color: #ffd700;
    border: solid #ffd700;
    color: #323232;
    font-weight: 650;
    padding: 8px 45px;
}

.upgradeNowButton:hover .upgradeNowButton:active {
    background: #d5b500;
    border: solid #d5b500;
}

.popupHeader {
    display: flex;
}

.closePopup {
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;
}

.closePopupButton {
    cursor: pointer;
}

.popupPrice {
    font-size: 24pt;
    margin-bottom: 0;
    color: #ffd700;
}

.popupPriceExplainer {
    margin-top: 0;
    font-size: 11pt;
}