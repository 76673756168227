.expandCollapseButtons {
    display: flex;
    margin-bottom: 10px;
    margin-top: 20px;
    justify-content: flex-start;
}

.expandCollapseButton {
    cursor: pointer;
    background: #1f2326;
    border-radius: 3px;
    border: 1px solid #575a64;
    margin-right: 10px;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    color: #f1f1f1;
}

.expandCollapseButton > svg {
    margin-right: 8px;
}

@media (max-width: 380px) {
    .expandCollapseButton {
        font-size: 10pt;
    }
}

.helpText {
    margin-bottom: 15px;
}