.combinationSelectorContainer.desktop {
    position: absolute;
    z-index: 100;
    width: 1090px;
    margin-left: 8px;
}

.combinationSelectorContainer.mobile {
    display: none;
}

.combinationSelector {
    background: #1f2326;
    border-radius: 5px;
    padding: 5px;
    border: 1px #575a64 solid;
    margin: 0 auto;
}

.combinationSelectorHeader {
    padding: 20px 15px 15px 17px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .combinationSelectorHeaderText {
        font-size: 15pt;
        font-weight: bold;
        margin-top: -2px;
    }

    .closeSelector {
        flex-grow: 1;
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
    }
}

.combinationSelectorButtons {
    margin-bottom: 10px;
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #1f2326;
}


@media (max-width: 1250px) {
    .combinationSelectorContainer.desktop {
        display: none;
    }

    .combinationSelectorContainer.mobile {
        display: block;

        .combinationSelectorButtons {
            height: 420px;
            overflow: auto;
        }
    }
}