.progressBarContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    margin-bottom: 30px;
}

.currentProgress {
    height: 100%;
    transition: flex 0.5s;
}

.progressText {
    position: absolute;
    left: 1em;
    right: 1em;
}

.inProgressCurrentProgress {
    background: #6c7693;
    border-radius: 25px 0 0 25px;
}

.finishedCurrentProgress {
    background: #77b677;
    border-radius: 25px 25px;
}

.currentProgress.failed {
    background: #8f5656;
}

.progressBar {
    display: flex;
    text-align: center;
    height: 40px;
    background: #546081;
    border-radius: 25px;
    align-items: center;
    width: 100%;
}

.progressBar.failed {
    background: #8f5656;
}

.progressWarning {
    background: #8a8a57;
}