.faqQuestion {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12pt;
    font-weight: bold;
}

.faqQuestionAnswerContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 20px;
}

.faqQuestionContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    background: #1f2326;
    color: #f1f1f1;
    cursor: pointer;
    padding: 15px;
}

.faqQuestionContainer.collapsed {
    border-radius: 5px;
}

.faqQuestionContainer.collapsed:active {
    background: #25292d;
}

@media (hover: hover) {
    .faqQuestionContainer.collapsed:hover {
        background: #25292d;
    }
}

.faqQuestionContainer.expanded {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.faqAnswer {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #1f2326;
}
