.deletePreferenceModal {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 425px;
    background: #1f2326;
    padding: 15px;
    border-radius: 7px;
    border: 1px solid #8f5656;
    color: #f1f1f1;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .deletePreferenceModal {
        width: 300px;
    }
}

.deletePreferenceModalHeader {
    display: flex;
}

.closeModal {
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;
}

.closeModalButton {
    cursor: pointer;
}

.dontShowModalAgain {
    display: flex;
}